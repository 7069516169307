import { render, staticRenderFns } from "./hasRegist.vue?vue&type=template&id=86fb20fa&scoped=true"
import script from "./hasRegist.vue?vue&type=script&lang=js"
export * from "./hasRegist.vue?vue&type=script&lang=js"
import style0 from "./hasRegist.vue?vue&type=style&index=0&id=86fb20fa&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86fb20fa",
  null
  
)

export default component.exports