<!--
 * @Description: 企业找回账号-企业审核-xxx公司已注册
 * @Version:
 * @Autor: houchuanfang
 * @Date: 2022-03-16 16:37:35
 * @LastEditTime: 2022-03-16 16:58:07
-->
<template>
  <div class="retrieve">
    <div class="title">
      <h3>兰州市残疾人就业创业网络服务平台 | 信息审核</h3>
      <router-link to="/">返回首页</router-link>
    </div>
    <div class="content">
      <div class="status">
        <div>江苏网新博创科技有限公司已被注册</div>
        <router-link to="/companyAudit">忘记账号密码？立即找回>></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      checkbox: '1',
      url: '/retrievePassword/phone',
    }
  },
  methods: {
    handChange(val) {
      if (val == 1) {
        this.url = '/retrievePassword/phone';
      } else if (val == 2) {
        this.url = '/retrievePassword/email';
      } else {
        this.url = '/retrievePassword/info';
      }
    },
    goRouter() {
      this.$router.push(this.url);
    }
  }
}
</script>

<style scoped lang="less">
.status {
  div {
    color: #00120A;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 18px;
  }
  a {
    color: #28A46D;
    font-size: 16px;
    font-weight: 600;
  }
}

.retrieve {
  width: 100%;
  background: white;
  position: relative;

  .w336 {
    width: 336px;
  }
  .title {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 26px;
    justify-content: center;

    h3 {
      flex: 1;
      font-size: 18px;
      color: #28A46D;
    }

    a {
      font-size: 18px;
      color: #66716C;
    }

    a:hover {
      color: #28A46D;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 404px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
